import { formatDate } from '@/helpers/DateFormatters';
import { cleanFilters } from '@/helpers/filter';

export default {
  methods: {
    resetCheckedFilters(checkedFilters, filters) {
      Object.keys(checkedFilters).forEach((key) => {
        if (!filters.hasOwnProperty(key)) {
          this.$delete(checkedFilters, key);
        } else if (checkedFilters[key].length > 0) {
          checkedFilters[key].forEach((value, index) => {
            if (!filters[key].includes(value)) {
              checkedFilters[key].splice(index, 1);
            }
          });
        }
      });
    },
    resetActiveStates() {
      const activeButtonElements = document.querySelectorAll(
        '.btn.btn--secondary.active'
      );
      activeButtonElements.forEach((el) => el.classList.toggle('active'));
    },
    cleanFilters() {
      return cleanFilters(this.filters);
    },
  },
};
