<template>
  <div class="">
    <div>
      <label class="text-lg font-sansbold">
        <slot name="filterLabel" />
      </label>
    </div>
    <div class="flex flex-wrap mt-2">
      <div
        v-for="(filter, index) in filters"
        :key="`state-${filter}--${index}`"
        class="filter-wrapper"
      >
        <label>
          <input
            :id="filter.id"
            :value="filter"
            class="filter-input"
            type="checkbox"
            @change="onFilterChange(filter.id, filter.type)"
          />
          <div
            class="w-auto btn btn--secondary"
            :class="{ active: isFilterActive(filter) }"
          >
            {{ filter.name }}
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GenericFilter',
  props: {
    value: {
      type: Array,
      default: function () {
        return [];
      },
    },
    filters: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  methods: {
    isFilterActive(filter) {
      if (this.value.length === 0) return false;

      const filterIndex = this.value.findIndex(
        (checkedFilter) =>
          checkedFilter.id === filter.id && checkedFilter.type === filter.type
      );
      if (filterIndex !== -1 && this.value[filterIndex].id === filter.id) {
        return true;
      }
      return false;
    },
    onFilterChange(id, type) {
      const index = this.value.findIndex(
        (filter) => filter.id === id && filter.type === type
      );
      let valueClone = [...this.value];
      if (index > -1) {
        valueClone.splice(index, 1);
      } else {
        valueClone.push({ id, type });
      }

      this.$emit('input', valueClone);
    },
  },
};
</script>

<style lang="postcss" scoped>
.filter-wrapper {
  height: 2.75rem;
}
</style>
