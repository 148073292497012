<template>
  <div class="pt-2">
    <!-- Date valid from filter -->
    <date-picker
      v-model="dateFrom"
      format="DD.MM.YYYY"
      class="date-picker pb-2"
      :placeholder="$i18n.t('admin.filters.validFrom')"
      :append-to-body="false"
    />

    <!-- Date valid to filter -->
    <date-picker
      v-model="dateTo"
      format="DD.MM.YYYY"
      class="date-picker pb-2"
      :placeholder="$i18n.t('admin.filters.validTo')"
      :append-to-body="false"
      :disabled="isDateToDisabled"
    />
    <!-- Open dates filter -->
    <div class="border-b flex items-center pb-2">
      <label for="openDatesFilter">
        {{ $t('admin.filters.openValidToDates') }}
      </label>
      <input
        id="openDatesFilter"
        v-model="openDates"
        type="checkbox"
        class="btn btn--primary ml-2"
      />
    </div>

    <button class="w-full mt-4 btn btn--primary" @click="filter">
      {{ $t('admin.documentList.filter') }}
    </button>
    <button class="mt-4 text-blue-primary btn--icon-left" @click="clearFilter">
      <svg-icon name="close" />
      {{ $t('admin.documentList.deleteFilter') }}
    </button>
  </div>
</template>

<script>
import DatePicker from '@/components/Datepicker.vue';
import 'vue2-datepicker/index.css';
import { formatDate } from '@/helpers/DateFormatters';

export default {
  name: 'AdminDateFilter',
  components: {
    DatePicker,
  },
  data() {
    return {
      openDates: false,
      dateFrom: '',
      dateTo: '',
      isDateToDisabled: false,
    };
  },
  watch: {
    openDates: function (newVal) {
      if (newVal) {
        this.isDateToDisabled = true;
        this.dateTo = '';
      } else {
        this.isDateToDisabled = false;
      }
    },
  },
  methods: {
    filter() {
      let formattedDates = {
        from: formatDate(this.dateFrom),
        to: formatDate(this.dateTo),
      };
      if (this.openDates) {
        formattedDates['openDates'] = this.openDates;
      } else {
        delete formattedDates['openDates'];
      }
      this.$emit('filter', formattedDates);
    },
    clearFilter() {
      this.openDates = false;
      this.dateFrom = '';
      this.dateTo = '';
      this.$emit('filter', '');
    },
  },
};
</script>
