<template>
  <button
    class="btn btn--secondary button-icon--wrapper"
    @click.prevent="deleteFilter"
  >
    {{ name || filterName }}
    <svg-icon class="svg-icon--lg close-icon" name="close" />
  </button>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
  },
  computed: {
    filterName() {
      return this.$store.state.Search.availableFilters.find(
        (filter) =>
          filter.id === this.value.id && filter.type === this.value.type
      )?.name;
    },
  },
  methods: {
    deleteFilter() {
      this.$emit('deleteFilter');
    },
  },
};
</script>

<style lang="postcss" scoped>
.frontend .button-icon--wrapper {
  @apply relative bg-gray-50;
  @apply hover:text-secondary-400;
  @apply hover:border-secondary-400;
}
.backend .button-icon--wrapper {
  @apply relative bg-gray-50;
  @apply hover:text-blue-800;
}

.button-icon--wrapper {
  @apply flex items-center justify-center;
}

.close-icon {
  @apply ml-2;
  @apply !h-5 w-5;
}
</style>
