import { formatDate } from '@/helpers/DateFormatters';

const cleanFilters = (filters) => {
  const cleanedFilters = {};
  Object.keys(filters).forEach((key) => {
    if (filters[key]) {
      if (key === 'date' || key === 'valid_from' || key === 'valid_to') {
        cleanedFilters[key] = formatDate(filters[key], 'YYYY-MM-DD');
      } else if (key === 'lang') {
        cleanedFilters[key] = filters[key];
      } else if (Array.isArray(filters[key])) {
        cleanedFilters[key] = filters[key].map((f) => f.id || f);
      } else {
        cleanedFilters[key] = filters[key];
      }
    }
  });
  return cleanedFilters;
};

export { cleanFilters };
