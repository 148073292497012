<template>
  <div>
    <v-popover ref="popover" popover-class="context-menu">
      <button class="font-sansbold btn--icon-right">
        <slot /><svg-icon name="menu" class="text-blue-500 svg-icon--lg" />
      </button>

      <template slot="popover">
        <div class="">
          <div class="context-menu--item">
            <button disabled>
              {{ $t('admin.documentList.contextMenu.choose') }}
            </button>
          </div>

          <!-- Action to add a new document in different language -->
          <div class="context-menu--item">
            <button
              :disabled="isLanguageAddDisabled"
              @click="addDocumentLanguage"
            >
              {{ $t('admin.documentList.contextMenu.addLanguage') }}
            </button>
          </div>

          <!-- Action to copy document to a new version -->
          <div class="context-menu--item">
            <button :disabled="!document.isCopyable" @click="copyDocument">
              {{ $t('admin.documentList.contextMenu.newVersion') }}
            </button>
          </div>

          <!-- Link to edit document -->
          <div class="context-menu--item">
            <router-link
              :to="{
                name: 'adminEditor',
                params: { id: document.id },
                query: { activeLang: document.documentLanguages[0] },
              }"
            >
              {{ $t('admin.documentList.contextMenu.editDocument') }}
            </router-link>
          </div>

          <!-- Action to delete document -->
          <div class="context-menu--item">
            <button :disabled="!document.isDeletable" @click="deleteDocument">
              {{ $t('admin.documentList.contextMenu.deleteDocument') }}
            </button>
          </div>
        </div>
      </template>
    </v-popover>
    <upload-document
      :is-open="isUploadModalOpen"
      :is-language-add="true"
      :is-from-context-menu="true"
      :document="document"
      @close="toggleUploadModal"
    />
  </div>
</template>

<script>
import EditorEventBus from '@/helpers/EditorEventBus';
import UploadDocument from '@/components/admin/upload/UploadDocument';

export default {
  name: 'ContextMenu',
  components: {
    UploadDocument,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isUploadModalOpen: false,
    };
  },
  computed: {
    isLanguageAddDisabled() {
      if (!this.document.isLanguageAddable) {
        return true;
      }
      return this.document.documentLanguages.length === 4;
    },
  },
  methods: {
    copyDocument() {
      EditorEventBus.$emit('copyDocument', this.document.id);
      this.$refs.popover.hide();
    },
    editDocument() {
      this.$router.push(`/admin/${this.document.id}`);
    },
    addDocumentLanguage() {
      this.isUploadModalOpen = true;
    },
    toggleUploadModal() {
      this.isUploadModalOpen = !this.isUploadModalOpen;
    },
    deleteDocument() {
      EditorEventBus.$emit('deleteDocument', this.document.id);
      this.$refs.popover.hide();
    },
  },
};
</script>

<style lang="postcss" scoped>
.context-menu {
  @apply bg-gray-50;
  margin-top: 10px !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 0 0 3px 3px;
}

.context-menu--item {
  @apply border-b border-dotted text-blue-500 px-2 py-1 cursor-pointer;
  & a {
    @apply w-full;
  }

  & button {
    @apply w-full text-left;
  }
}

.context-menu--item:first-of-type {
  @apply border-none;
}

.context-menu--item:not(:first-of-type):hover {
  @apply bg-blue-500 text-white;

  & a {
    @apply text-white;
  }
}

.context-menu--item button[disabled] {
  @apply text-gray-400 cursor-not-allowed;
}

.context-menu--item a {
  @apply no-underline text-blue-500 w-full;
  transition: none;
}
</style>
