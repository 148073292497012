<template>
  <div>
    <generic-filter v-model="selectedOriginsOfLaw" :filters="originsOfLaw">
      <template #filterLabel>
        {{ $t('document.labels.originsOfLaw') }}
      </template>
    </generic-filter>
  </div>
</template>

<script>
import GenericFilter from '@/components/GenericFilter';

export default {
  name: 'DocumentOriginOfLawFilter',
  components: {
    GenericFilter,
  },
  props: {
    value: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  computed: {
    selectedOriginsOfLaw: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('input', newVal);
      },
    },
    originsOfLaw() {
      return this.$store.state.Search.availableFilters.filter(
        (filter) => filter.type === 'originOfLaw'
      );
    },
  },
};
</script>

<style scoped></style>
