<template>
  <div class="relative flex w-full max-w-lg">
    <input
      v-model="searchTerm"
      type="text"
      :placeholder="$i18n.t('admin.documentList.search.placeholder')"
      class="w-full rounded-r-none"
      @keyup.enter="setSearchTerm"
    />
    <button
      class="px-4 rounded-l-none btn btn--primary btn--icon btn--icon-left"
      @click="setSearchTerm"
    >
      <svg-icon name="search" class="svg-icon--lg" />
      {{ $t('admin.documentList.search.button') }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'DocumentSearch',
  data() {
    return {
      searchTerm: '',
    };
  },
  methods: {
    setSearchTerm() {
      this.$emit('searchDocuments', this.searchTerm);
    },
  },
};
</script>
