<template>
  <div
    class="
      flex
      3xl:flex-row
      flex-col
      3xl:items-center
      justify-between
      3xl:w-1/3
      w-1/4
    "
  >
    <!-- Date valid from filter -->
    <div class="flex flex-col">
      <label class="text-lg font-sansbold">
        {{ $t('document.labels.validFrom') }}
      </label>
      <date-picker
        v-model="dateFrom"
        format="DD.MM.YYYY"
        class="pb-2 mt-2 date-picker"
        :placeholder="$i18n.t('document.placeholders.validFrom')"
        :append-to-body="false"
      />
    </div>

    <!-- Date valid to filter -->
    <div class="flex flex-col">
      <label class="text-lg font-sansbold">
        {{ $t('document.labels.validTo') }}
      </label>
      <date-picker
        v-model="dateTo"
        format="DD.MM.YYYY"
        class="pb-2 mt-2 date-picker"
        :placeholder="$i18n.t('document.placeholders.validTo')"
        :append-to-body="false"
      />
    </div>
  </div>
</template>

<script>
import DatePicker from '@/components/Datepicker.vue';
import 'vue2-datepicker/index.css';
import dayjs from 'dayjs';

export default {
  name: 'DocumentDateFilter',
  components: {
    DatePicker,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dateFrom: '',
      dateTo: '',
    };
  },
  watch: {
    dateFrom: function (newDate) {
      if (!newDate) {
        return;
      }
      this.$emit('setFromDate', newDate);
    },
    dateTo: function (newDate) {
      if (!newDate) {
        return;
      }
      this.$emit('setToDate', newDate);
    },
  },
  mounted() {
    this.dateFrom = this.filters.valid_from
      ? dayjs(this.filters.valid_from).$d
      : '';
    this.dateTo = this.filters.valid_to ? dayjs(this.filters?.valid_to).$d : '';
  },
  methods: {
    resetFilter() {
      this.dateFrom = '';
      this.dateTo = '';
    },
  },
};
</script>

<style lang="postcss" scoped>
.date-picker {
  @apply !w-auto;
}
</style>
