<template>
  <div>
    <generic-filter v-model="selectedStates" :filters="states">
      <template #filterLabel>
        {{ $t('document.labels.state') }}
      </template>
    </generic-filter>
  </div>
</template>

<script>
import GenericFilter from '@/components/GenericFilter';

export default {
  name: 'DocumentStateFilter',
  components: {
    GenericFilter,
  },
  props: {
    value: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  computed: {
    selectedStates: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('input', newVal);
      },
    },
    states() {
      return this.$store.state.Search.availableFilters.filter(
        (filter) => filter.type === 'state'
      );
    },
  },
};
</script>
