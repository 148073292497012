<template>
  <th>
    <v-popover v-if="filterable" ref="popover">
      <button class="font-sansbold btn--icon-right">
        <slot /><svg-icon name="angle-down" />
      </button>

      <template slot="popover">
        <div class="popover-container">
          <p class="pb-2 font-sansbold border-b">
            {{ $t('admin.documentList.filterBy', { name }) }}
          </p>

          <!-- Language filter -->
          <admin-language-filter v-if="isLanguageFilter" @filter="filter" />

          <!-- Keyword filter -->
          <admin-keyword-filter
            v-if="isKeywordFilter"
            :keywords="keywords"
            @filter="filter"
          />

          <!-- Date filter -->
          <admin-date-filter v-if="isDateFilter" @filter="filter" />
        </div>
      </template>
    </v-popover>
    <slot v-else />
  </th>
</template>

<script>
import AdminDateFilter from './AdminDateFilter';
import AdminLanguageFilter from './AdminLanguageFilter';
import AdminKeywordFilter from './AdminKeywordFilter';

export default {
  components: {
    AdminDateFilter,
    AdminLanguageFilter,
    AdminKeywordFilter,
  },
  props: {
    filterable: {
      type: Boolean,
      default: false,
    },
    filterKey: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    keywords: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  computed: {
    isKeywordFilter() {
      return this.filterKey === 'keywords';
    },
    isLanguageFilter() {
      return this.filterKey === 'language';
    },
    isDateFilter() {
      return this.filterKey === 'date_range';
    },
  },
  methods: {
    filter(filterValue) {
      this.$emit('filter', this.filterKey, filterValue);
      this.hidePopover();
    },
    hidePopover() {
      this.$refs.popover.hide();
    },
  },
};
</script>

<style lang="postcss"></style>
