<template>
  <div class="flex flex-wrap items-center mt-4">
    <div
      v-for="(filterName, key) in dateFilters"
      :key="`date-filter--${key}`"
      class="mt-2 mr-2"
    >
      <filter-badge
        :name="filterName"
        @deleteFilter="deleteFilter(filterName, key)"
      />
    </div>
    <div
      v-for="(filters, filterKey) in selectedFilters"
      :key="`filter-wrapper--${filters[filterKey]}---${filterKey}`"
      class="flex"
    >
      <div
        v-for="(value, index) in filters"
        :key="`filter-button--${value}---${index}`"
        class="mt-2 mr-2"
      >
        <filter-badge
          :value="value"
          @deleteFilter="deleteFilter(value, filterKey)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/helpers/DateFormatters';
import FilterBadge from '@/components/FilterBadge';

export default {
  name: 'DocumentSelectedFilter',
  components: {
    FilterBadge,
  },
  props: {
    selectedFilters: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dateFilters() {
      let dateFilterTranslations = {
        valid_from: this.$t('selectedFilters.validFrom'),
        valid_to: this.$t('selectedFilters.validTo'),
        date: this.$t('selectedFilters.date'),
      };
      let cleanedDateFilters = {};
      Object.keys(this.selectedFilters).forEach((key) => {
        if (dateFilterTranslations[key]) {
          cleanedDateFilters[key] = `${
            dateFilterTranslations[key]
          }: ${formatDate(this.selectedFilters[key])}`;
        }
      });
      return cleanedDateFilters;
    },
  },
  methods: {
    deleteFilter(filter, filterKey) {
      this.$emit('deleteFilter', filter, filterKey);
    },
  },
};
</script>
