import { render, staticRenderFns } from "./DocumentTypeOfLawFilter.vue?vue&type=template&id=54dc0ec8&scoped=true"
import script from "./DocumentTypeOfLawFilter.vue?vue&type=script&lang=js"
export * from "./DocumentTypeOfLawFilter.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54dc0ec8",
  null
  
)

export default component.exports