<template>
  <admin-layout class="backend">
    <div class="px-24 py-12 my-24 bg-white">
      <h1 class="text-5xl">{{ $t('content.title') }}</h1>
      <div class="flex justify-between mt-12">
        <div>
          <button class="flex btn btn--primary" @click="openPdfModal">
            <svg-icon class="w-6 h-6 mr-3 text-white" name="add-file" />
            {{ $t('upload.button') }}
          </button>
          <upload-document :is-open="isPdfModalOpen" @close="closePdfModal" />
        </div>
      </div>
      <div class="flex flex-col items-start mt-20 lg:flex-row lg:items-center">
        <admin-document-filter
          ref="adminDocumentFilterOverlay"
          class="mr-10 md:mb-2"
          @filterDocuments="filterDocuments"
          @selectedFilters="setSelectedFilters"
        />
        <document-search class="md:mb-2" @searchDocuments="searchDocuments" />
        <admin-document-list-pagination
          ref="pagination"
          class="flex xl:ml-auto backend"
          :count="documentsPagination.count"
          @goto="changePage"
        />
      </div>
      <document-selected-filter
        class="backend"
        :selected-filters="selectedFilters"
        @deleteFilter="deleteFilter"
      />
      <admin-document-list
        :documents-pagination="documentsPagination"
        class="mt-4"
        @reloadDocumentList="reloadDocumentList"
        @sortDocuments="sortDocuments"
      />
    </div>
  </admin-layout>
</template>

<script>
import AdminDocumentList from '@/components/admin/AdminDocumentList';
import UploadDocument from '../components/admin/upload/UploadDocument.vue';
import DocumentSearch from '@/components/admin/list/DocumentSearch';
import AdminDocumentListPagination from '@/components/admin/AdminDocumentListPagination';
import EditorEventBus from '@/helpers/EditorEventBus';
import DocumentSelectedFilter from '@/components/DocumentSelectedFilter';
import AdminDocumentFilter from '@/components/admin/list/AdminDocumentFilter';
import { getDocuments } from '@/services/document';
import { cleanFilters } from '@/helpers/filter';

export default {
  name: 'Admin',
  components: {
    AdminDocumentList,
    UploadDocument,
    DocumentSearch,
    AdminDocumentListPagination,
    DocumentSelectedFilter,
    AdminDocumentFilter,
  },
  data() {
    return {
      isPdfModalOpen: false,
      documentsPagination: [],
      currentPage: 1,
      sort: {},
      selectedFilters: {},
      searchTerm: '',
    };
  },
  watch: {
    '$i18n.locale'() {
      this.$store.dispatch('Search/loadAvailableFilters');
    },
  },
  async mounted() {
    document.body.classList.add('backend');
    document.body.classList.remove('frontend');
    this.documentsPagination = await getDocuments();
    EditorEventBus.$on('reloadDocumentList', this.reloadDocumentList);
    this.$store.dispatch('Search/loadAvailableFilters');
  },
  beforeDestroy() {
    EditorEventBus.$off('reloadDocumentList', this.reloadDocumentList);
  },
  methods: {
    openPdfModal() {
      this.isPdfModalOpen = true;
    },
    closePdfModal() {
      this.isPdfModalOpen = false;
    },
    async searchDocuments(searchTerm) {
      this.searchTerm = searchTerm;
      this.currentPage = 1;
      this.$refs.pagination.setCurrentPage(1);
      await this.getDocuments();
    },
    async changePage(page) {
      this.currentPage = page;

      await this.getDocuments();
    },
    async reloadDocumentList() {
      await this.getDocuments();
    },
    async sortDocuments(filterKey, value) {
      this.sort[filterKey] = value;

      await this.getDocuments();
    },
    async filterDocuments(filters) {
      this.selectedFilters = filters;
      this.currentPage = 1;
      this.$refs.pagination.setCurrentPage(1);

      await this.getDocuments();
    },
    setSelectedFilters(filters) {
      this.selectedFilters = filters;
    },
    async getDocuments() {
      this.documentsPagination = await getDocuments(
        { ...cleanFilters(this.selectedFilters), ...this.sort },
        this.currentPage,
        this.searchTerm
      );
    },
    deleteFilter(filter, filterKey) {
      Object.keys(this.selectedFilters).forEach((key) => {
        if (key === filterKey) {
          // Delete selected filter with value as array.
          if (this.selectedFilters[key].length > 0) {
            this.selectedFilters[key].splice(
              this.selectedFilters[key].indexOf(filter),
              1
            );
          } else {
            // Delete cleaned and selected filter with only one value.
            this.$delete(this.selectedFilters, key);
            if (filterKey === 'valid_to') {
              this.$refs.adminDocumentFilterOverlay.setToDate(null);
            }
            if (filterKey === 'valid_from') {
              this.$refs.adminDocumentFilterOverlay.setFromDate(null);
            }
          }
        }
      });
      this.filterDocuments(
        this.selectedFilters,
        this.currentPage,
        this.searchTerm
      );
    },
  },
};
</script>
