<template>
  <div>
    <div>
      <button class="filter-link" @click="toggleFilterModal">
        <svg-icon name="filter" class="mr-4 svg-icon--lg" />
        {{ $t('general.filters') }}
      </button>
    </div>
    <modal
      v-if="isFilterModalOpen"
      :is-open="isFilterModalOpen"
      class-container="flex flex-col full-screen"
      class-body="overflow-y-auto"
      @closeModal="close"
    >
      <div slot="header">
        <slot name="documentFilterTitle" />
      </div>

      <slot name="documentFilterBody" />

      <div slot="footer">
        <slot name="documentFilterFooter" />
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import filterMixin from '@/components/filterMixin';

export default {
  name: 'DocumentFilter',
  components: {
    Modal,
  },
  mixins: [filterMixin],
  props: {
    checkedFilters: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isFilterModalOpen: false,
    };
  },
  methods: {
    toggleFilterModal() {
      this.isFilterModalOpen = !this.isFilterModalOpen;
    },
    close(isFiltering) {
      this.isFilterModalOpen = false;
      if (!isFiltering) {
        this.resetCheckedFilters(this.checkedFilters, this.filters);
      }
    },
  },
};
</script>
<style lang="postcss">
.full-screen {
  max-width: none;
}
</style>
