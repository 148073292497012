<template>
  <div class="w-1/2">
    <generic-filter v-model="selectedCreators" :filters="creators">
      <template #filterLabel>
        {{ $t('document.labels.creator') }}
      </template>
    </generic-filter>
  </div>
</template>

<script>
import GenericFilter from '@/components/GenericFilter';

export default {
  name: 'DocumentCreatorFilter',
  components: {
    GenericFilter,
  },
  props: {
    value: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  computed: {
    selectedCreators: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('input', newVal);
      },
    },
    creators() {
      return this.$store.state.Search.availableFilters.filter(
        (filter) => filter.type === 'creator'
      );
    },
  },
};
</script>

<style scoped></style>
