<template>
  <div>
    <multi-select
      v-model="submittedKeywords"
      :options="keywords"
      track-by="id"
      label="name"
      class="mt-4 keyword-multiselect"
      :placeholder="$i18n.t('upload.uploadForm.placeholders.keywords')"
      :multiple="true"
    />

    <button class="w-full mt-4 btn btn--primary" @click="filter">
      {{ $t('admin.documentList.filter') }}
    </button>
    <button class="mt-4 text-blue-primary btn--icon-left" @click="clearFilter">
      <svg-icon name="close" />
      {{ $t('admin.documentList.deleteFilter') }}
    </button>
  </div>
</template>

<script>
import MultiSelect from '@/components/Select';

export default {
  name: 'AdminKeywordFilter',
  components: {
    MultiSelect,
  },
  props: {
    keywords: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
  },
  data() {
    return {
      submittedKeywords: [],
    };
  },
  methods: {
    filter() {
      let cleanedKeywords = this.cleanKeywords(this.submittedKeywords);
      this.$emit('filter', cleanedKeywords);
    },
    clearFilter() {
      this.submittedKeywords = [];
      this.$emit('filter', '');
    },
    cleanKeywords(keywords) {
      return keywords.map((keyword) => {
        return keyword.id;
      });
    },
  },
};
</script>

<style lang="postcss" scoped>
.keyword-multiselect {
  width: 28rem !important;
}
</style>
