<template>
  <div>
    <document-filter
      ref="adminDocumentFilterModal"
      :filters="filters"
      :checked-filters="checkedFilters"
      class="backend"
    >
      <template #documentFilterTitle>
        <h3>{{ $t('document.filters.title') }}</h3>
      </template>
      <template #documentFilterBody>
        <!-- Date filter -->
        <document-date-filter
          ref="documentDateFilter"
          :filters="checkedFilters"
          @setToDate="setToDate"
          @setFromDate="setFromDate"
        />

        <!-- State filter -->
        <document-state-filter
          v-model="checkedFilters.validity_state"
          class="mt-8"
        />

        <div class="flex items-center mt-8 w-full">
          <!-- Creator filter -->
          <document-creator-filter v-model="checkedFilters.creator" />

          <!-- Type of law filter -->
          <document-type-of-law-filter
            v-model="checkedFilters.type_of_law"
            class="w-1/2"
          />
        </div>

        <!-- Origin of law filter -->
        <document-origin-of-law-filter
          v-model="checkedFilters.origin_of_law"
          class="mt-8"
        />
      </template>
      <template #documentFilterFooter>
        <div class="flex justify-between">
          <button class="btn btn--secondary" @click="resetFilters">
            {{ $t('document.filters.resetFilters') }}
          </button>
          <button class="btn btn--primary" @click="filter">
            {{ $t('document.filters.filter') }}
          </button>
        </div>
      </template>
    </document-filter>
  </div>
</template>

<script>
import DocumentFilter from '@/components/DocumentFilter';
import DocumentDateFilter from '@/components/admin/list/DocumentDateFilter';
import DocumentStateFilter from '@/components/admin/list/DocumentStateFilter';
import DocumentCreatorFilter from '@/components/admin/list/DocumentCreatorFilter';
import DocumentTypeOfLawFilter from '@/components/admin/list/DocumentTypeOfLawFilter';
import DocumentOriginOfLawFilter from '@/components/admin/list/DocumentOriginOfLawFilter';
import filterMixin from '@/components/filterMixin';

export default {
  name: 'AdminDocumentFilter',
  components: {
    DocumentFilter,
    DocumentDateFilter,
    DocumentStateFilter,
    DocumentCreatorFilter,
    DocumentTypeOfLawFilter,
    DocumentOriginOfLawFilter,
  },
  mixins: [filterMixin],
  data() {
    return {
      filters: {},
      checkedFilters: {},
    };
  },
  methods: {
    resetFilters() {
      this.filters = {};
      const selectedFilters = {};
      this.checkedFilters = {};
      this.resetActiveStates();
      this.$refs.documentDateFilter.resetFilter();
      this.$emit('filterDocuments', this.filters);
      this.$emit('selectedFilters', selectedFilters);
    },
    async filter() {
      this.filters = Object.assign({}, this.checkedFilters);
      const cleanedFilter = this.cleanFilters();

      this.$emit('filterDocuments', cleanedFilter);
      this.$emit('selectedFilters', this.filters);
      this.$refs.adminDocumentFilterModal.close(true);
    },
    setFromDate(date) {
      if (this.checkedFilters.hasOwnProperty('valid_from') && date) return;
      if (date) {
        this.$set(this.checkedFilters, 'valid_from', date);
      } else {
        this.$delete(this.checkedFilters, 'valid_from');
      }
    },
    setToDate(date) {
      if (this.checkedFilters.hasOwnProperty('valid_to') && date) return;
      if (date) {
        this.$set(this.checkedFilters, 'valid_to', date);
      } else {
        this.$delete(this.checkedFilters, 'valid_to');
      }
    },
  },
};
</script>
