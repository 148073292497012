<template>
  <div>
    <div
      v-for="(lang, index) in $i18n.availableLocales"
      :key="`language-filter--${lang}---${index}`"
      class="flex items-center border-b pb-1 pt-1"
    >
      <input
        type="checkbox"
        class="mr-2 languageFilterCheckbox"
        :value="languages[lang]"
        @click="setLanguage(lang)"
      />
      <span>{{ lang.toUpperCase() }}</span>
    </div>
    <button class="w-full mt-4 btn btn--primary" @click="filter">
      {{ $t('admin.documentList.filter') }}
    </button>
    <button class="mt-4 text-blue-primary btn--icon-left" @click="clearFilter">
      <svg-icon name="close" />
      {{ $t('admin.documentList.deleteFilter') }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'AdminLanguageFilter',
  data() {
    return {
      languages: [],
    };
  },
  methods: {
    filter() {
      this.$emit('filter', this.languages);
    },
    clearFilter() {
      this.languages = [];
      this.clearCheckboxes();
      this.$emit('filter', '');
    },
    setLanguage(lang) {
      if (this.languages.includes(lang)) {
        let langIndex = this.languages.indexOf(lang);
        this.languages.splice(langIndex, 1);
      } else {
        this.languages.push(lang);
      }
    },
    clearCheckboxes() {
      const languageCheckboxes = document.querySelectorAll(
        '.languageFilterCheckbox'
      );

      for (let i = 0; i < languageCheckboxes.length; i++) {
        languageCheckboxes[i].checked = false;
      }
    },
  },
};
</script>

<style scoped></style>
