<template>
  <div>
    <button class="sort-filter" type="button" @click="changeSortState">
      <svg-icon v-if="isNotSorted" name="sort" />
      <svg-icon v-else-if="isAscendingSorted" name="sort-up" class="pb-2" />
      <svg-icon v-else name="sort-down" class="pt-2" />
    </button>
  </div>
</template>

<script>
export default {
  name: 'AdminSortFilter',
  props: {
    filterKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      sortState: 0,
      skipNextFilter: false,
    };
  },
  computed: {
    isNotSorted() {
      return this.sortState === 0;
    },
    isAscendingSorted() {
      return this.sortState === 1;
    },
  },
  watch: {
    sortState: function (newVal) {
      if (this.skipNextFilter) {
        this.skipNextFilter = false;
        return;
      }
      this.$emit('filter', this.filterKey, this.getSortStateForAPI());
    },
  },
  methods: {
    changeSortState() {
      // If sort state is already 2 we know he has sorted it ascending and
      // descending and thus we can take it back to not sorted state
      if (this.sortState === 2) {
        this.sortState = 0;
      } else {
        this.sortState += 1;
      }
    },
    resetState() {
      // Skip the next filtering because we dont want to trigger an api request
      if (this.sortState !== 0) {
        this.skipNextFilter = true;
      }

      this.sortState = 0;
    },
    getSortStateForAPI() {
      if (this.sortState === 0) {
        return '';
      }
      return (this.sortState === 2 ? '-' : '') + this.filterKey;
    },
  },
};
</script>

<style lang="postcss">
.backend .sort-filter {
  @apply text-blue-600;
}

.frontend .sort-filter {
  @apply text-secondary-600;
}
</style>
