<template>
  <div v-if="documentsPagination">
    <table>
      <tr>
        <th class="w-8" />
        <admin-document-list-header class="w-1/3">
          <div class="flex items-center">
            {{ $t('admin.filters.title') }}
            <admin-sort-filter
              ref="filter_title"
              class="flex items-center ml-2"
              filter-key="title"
              @filter="sortDocuments"
            />
          </div>
        </admin-document-list-header>
        <admin-document-list-header>
          <div class="flex items-center">
            {{ $t('admin.filters.creator') }}
            <admin-sort-filter
              ref="filter_creator"
              class="flex items-center ml-2"
              filter-key="creator"
              @filter="sortDocuments"
            />
          </div>
        </admin-document-list-header>
        <admin-document-list-header>
          <div class="flex items-center">
            {{ $t('admin.filters.typeOfLaw') }}
            <admin-sort-filter
              ref="filter_type_of_law"
              class="flex items-center ml-2"
              filter-key="type_of_law"
              @filter="sortDocuments"
            />
          </div>
        </admin-document-list-header>
        <admin-document-list-header>
          {{ $t('admin.filters.languages') }}
        </admin-document-list-header>
        <admin-document-list-header>
          {{ $t('admin.filters.validityPeriod') }}
        </admin-document-list-header>
        <th class="w-10" />
      </tr>
      <admin-document-list-entry
        v-for="document in documentsPagination.results"
        :key="document.id"
        :document="document"
      />
    </table>
  </div>
</template>

<script>
import { copyDocument, deleteDocument } from '@/services/document';
import AdminDocumentListEntry from '@/components/admin/AdminDocumentListEntry';
import AdminDocumentListHeader from '@/components/admin/AdminDocumentListHeader';
import AdminSortFilter from './AdminSortFilter';
import EditorEventBus from '@/helpers/EditorEventBus';

const AVAILABLE_FILTERS = ['title', 'creator', 'type_of_law'];

export default {
  components: {
    AdminDocumentListEntry,
    AdminDocumentListHeader,
    AdminSortFilter,
  },
  props: {
    documentsPagination: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filter: {},
    };
  },
  async mounted() {
    EditorEventBus.$on('copyDocument', this.copyDocument);
    EditorEventBus.$on('deleteDocument', this.deleteDocument);
  },
  beforeDestroy() {
    EditorEventBus.$off('copyDocument', this.copyDocument);
    EditorEventBus.$off('deleteDocument', this.deleteDocument);
  },
  methods: {
    sortDocuments(filterKey, value) {
      // Reset state from all other filters
      AVAILABLE_FILTERS.forEach((filter) => {
        if (filter !== filterKey) {
          this.$refs['filter_' + filter].resetState();
        }
      });

      this.$emit('sortDocuments', 'ordering', value);
    },
    async copyDocument(documentId) {
      try {
        await copyDocument(documentId);
        this.$emit('reloadDocumentList');
        this.$notify({
          type: 'success',
          text: this.$t('admin.documentList.copySuccess').toString(),
        });
      } catch (e) {
        this.$notify({
          type: 'error',
          text:
            e.response?.data?.message ||
            this.$t('admin.documentList.copyError').toString(),
        });
      }
    },
    deleteDocument(documentId) {
      try {
        const confirmMessage = {
          title: this.$t('admin.documentList.deleteDocumentConfirmation.title'),
          body: {
            note: this.$t('admin.documentList.deleteDocumentConfirmation.body'),
          },
        };
        const options = {
          okText: this.$t('admin.documentList.deleteDocumentConfirmation.yes'),
          cancelText: this.$t(
            'admin.documentList.deleteDocumentConfirmation.no'
          ),
        };
        this.$dialog.confirm(confirmMessage, options).then(async () => {
          await deleteDocument(documentId);
          this.$emit('reloadDocumentList');
          this.$notify({
            type: 'success',
            text: this.$t('admin.documentList.deleteSuccess').toString(),
          });
        });
      } catch (e) {
        this.$notify({
          type: 'error',
          text:
            e.response?.data?.message ||
            this.$t('admin.documentList.deleteError').toString(),
        });
      }
    },
  },
};
</script>
