<template>
  <tr class="cursor-pointer hover:bg-blue-50" @click="onDocumentClick">
    <td>
      <document-state :document="document" />
    </td>
    <td class="w-1/3">{{ documentTitle }}</td>
    <td>
      <span v-if="document.creator">{{ document.creator.name }}</span>
    </td>
    <td>
      <span v-if="document.typeOfLaw">{{ document.typeOfLaw.name }}</span>
    </td>
    <td @click.stop>
      <router-link
        v-for="(lang, key) in document.documentLanguages"
        :key="key"
        class="language-link"
        :to="{
          name: 'adminEditor',
          params: { id: document.id },
          query: { activeLang: lang },
        }"
        >{{ lang.toUpperCase() }}</router-link
      >
    </td>
    <td>
      {{ validDate }}
    </td>
    <td @click.stop>
      <context-menu :document="document" />
    </td>
  </tr>
</template>

<script>
import dayjs from 'dayjs';
import ContextMenu from '@/components/admin/list/ContextMenu';
import DocumentState from '@/components/DocumentState';

export default {
  components: {
    ContextMenu,
    DocumentState,
  },
  props: {
    document: {
      type: Object,
      required: true,
    },
  },
  computed: {
    validDate() {
      const dateFormat = 'DD.MM.YYYY';
      if (!this.document.validFrom)
        return this.$i18n.t('admin.documentList.noValidFrom');

      if (this.document.validTo) {
        const fromDate = dayjs(this.document.validFrom).format(dateFormat);
        const toDate = dayjs(this.document.validTo).format(dateFormat);
        return `${fromDate} - ${toDate}`;
      }
      return `${dayjs(this.document.validFrom).format(
        dateFormat
      )} - ${this.$i18n.t('admin.documentList.noValidTo')}`;
    },
    isCopiedDocument() {
      if (this.document.importType !== 'full' || !this.rootBlock) return false;
      return !!(
        this.document.state === 'draft' && this.document.block.hasHistory
      );
    },
    documentPdfFileName() {
      return this.document.pdf.split('/').pop();
    },
    documentTitle() {
      if (!this.document.importFinished && this.document.pdf) {
        if (this.document.failedLanguages.includes(this.getUserLanguage())) {
          return this.$i18n.t('general.failed', {
            documentTitle: this.documentPdfFileName,
          });
        }
        return this.$i18n.t('general.importing', {
          documentTitle: this.documentPdfFileName,
        });
      }
      if (this.isCopiedDocument) {
        return this.$i18n.t('general.isCopyLabel', {
          documentTitle: this.document.title ? this.document.title : '',
        });
      }
      return this.document.title ? this.document.title : '';
    },
  },
  methods: {
    getUserLanguage() {
      if (this.document.documentLanguages.includes(this.$i18n.locale)) {
        return this.$i18n.locale;
      }
      return this.document.documentLanguages[0];
    },
    onDocumentClick() {
      this.$router.push({
        name: 'adminEditor',
        params: {
          id: this.document.id,
        },
        query: {
          activeLang: this.getUserLanguage(),
        },
      });
    },
  },
};
</script>

<style lang="postcss" scoped>
.language-link {
  @apply text-blue-600;
}

.language-link:first-of-type {
  margin-left: 0 !important;
}

.language-link {
  @apply ml-2;
}
</style>
